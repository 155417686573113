import React, { useEffect, useState } from "react";
import { Modal, Button, Table, Col, Row } from "react-bootstrap";

const BotonOfertasActivas = (props: any) => {
    const [show, setShow] = useState(false);

    useEffect(() => { }, [show]);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const ofertas = props.ofertas || [];
    const precio = props.precio || [];


    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth < 768);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    if (!show) {
        return (
            <button className="btn btn-warning btn-sm mt-2" onClick={handleShow}> + Ofertas </button>
        );
    }

    return (<div>
        <button className="btn btn-warning btn-sm mt-2" onClick={handleShow}> + Ofertas </button>
        {/* Modal */}
        <Modal className="wd-80p d-flex m-auto"
            dialogClassName="modal-90w m-auto" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Ofertas Activas</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                    {isMobile ? (
                        <>
                            {/* Mostrar detalle una sola vez en móvil */}
                            {ofertas.length > 0 && (
                                <h5 className="fw-bold mb-3">Detalle: {ofertas[0].detalleDescuento}</h5>
                            )}

                            {ofertas.map((oferta: any) => (
                                <div key={oferta.idDescuento} className="border p-3 mb-3 ">
                                    <p><strong>Cantidad Mínima:</strong> {oferta.cantidadMinimaDescuento}</p>
                                    <Row>
                                        <Col xs={4}>
                                            <p className="fs-6"><strong>Bonificación:</strong> {oferta.porcentajeBonificacionDescuento}%</p>
                                        </Col>
                                        <Col xs={8} className="text-end">
                                            {oferta.precioUnitarioConDescuento && oferta.precioUnitarioConDescuento < precio ? (
                                                <>
                                                    <strong className="text-muted text-decoration-line-through">
                                                        ${precio.toFixed(2)}
                                                    </strong>
                                                    <div className="mt-1 bg-success-subtle text-success  ">
                                                        <strong className="fs-3 fw-bold">
                                                            ${oferta.precioUnitarioConDescuento.toFixed(2)}
                                                        </strong>
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <strong className="fs-3">${precio.toFixed(2)}</strong>
                                                </>
                                            )}
                                        </Col>
                                    </Row>

                                </div>
                            ))}
                        </>
                    ) : (
                        <>
                            {/* Mostrar el detalle una sola vez arriba de la tabla */}
                            {ofertas.length > 0 && (
                                <h5 className="fw-bold mb-3">Detalle: {ofertas[0].detalleDescuento}</h5>
                            )}

                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Cantidad Mínima</th>
                                        <th>Bonificación (%)</th>
                                        <th>Precio con Descuento</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {ofertas.map((oferta: any) => (
                                        <tr key={oferta.idDescuento}>
                                            <td className="text-end">{oferta.cantidadMinimaDescuento}</td>
                                            <td className="text-end">{oferta.porcentajeBonificacionDescuento}%</td>
                                            <td className="text-end">
                                                {oferta.precioUnitarioConDescuento && oferta.precioUnitarioConDescuento < precio ? (
                                                    <>
                                                        {/* <span className="font-weight-bold">Precio: </span> */}
                                                        <strong className="text-muted text-decoration-line-through">
                                                            ${precio.toFixed(2)}
                                                        </strong>
                                                        <div>
                                                            <strong className="text-success">
                                                                ${oferta.precioUnitarioConDescuento.toFixed(2)}
                                                            </strong>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        {/* <span className="font-weight-bold">Precio: </span> */}
                                                        <strong>${precio.toFixed(2)}</strong>
                                                    </>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </>
                    )}
                </div>
            </Modal.Body>


            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    </div>)



};

export default BotonOfertasActivas;

