import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { formatearPrecio } from '../../functions/Procedimientos';
import BotonOfertasActivas from '../common/BotonOfertaActiva';

/**
 * Objeto creado para mostrar el precio de un producto y el boton de ofertas activas
 * en todas sus variantes. Estel elemento debe ser usado en la vista de producto
 * @param {*} articulo objeto que contiene la informacion del producto dto del backend 
 * @returns visualizacion de precios con precio normal, precio con descuento y boton de ofertas activas
 */
const PrecioProducto = ({ articulo }) => {
    const cantidadOfertas = articulo.articuloDescuentosAplicables.length || 0;
    const ofertaConCantidadMinimaIgualAUno = articulo.articuloDescuentosAplicables.find(oferta => oferta.cantidadMinimaDescuento === 1);
    
    //En caso de no existir ofertas envio precio comun
    if (cantidadOfertas === 0)
        return (
            <div className="text-end text-lg font-weight-bold tx-15">
                <strong className="text-end text-lg font-weight-bold tx-15">
                    {formatearPrecio({ valor: articulo.articuloPrecio })}
                </strong>
            </div>
        );

    //Si existen ofertas entonces me pongo manos a la obra
    //Si existe una oferta que tiene como cantidad minima 1 entonces muestro el precio con descuento
    //ya aplicado por que el cliente si compra va a recibir ese precio
    if (ofertaConCantidadMinimaIgualAUno !== undefined)
        return (
            <div className="text-end text-lg font-weight-bold tx-15">
                <div>
                    <span className="text-end text-muted text-decoration-line-through tx-12">
                        {formatearPrecio({ valor: articulo.articuloPrecio })}
                    </span>
                </div>
                <div className="d-flex flex-column align-items-end">
                    <strong className={`text-end text-lg font-weight-bold tx-15 ${ofertaConCantidadMinimaIgualAUno && 'text-success'}`}>
                        {formatearPrecio({ valor: ofertaConCantidadMinimaIgualAUno.precioUnitarioConDescuento })}
                    </strong>
                </div>
                {cantidadOfertas > 1 &&
                    <div>
                        {/* Mostrar siempre el botón para múltiples ofertas */}
                        <BotonOfertasActivas
                            ofertas={articulo.articuloDescuentosAplicables}
                            precio={articulo.articuloPrecio}
                        />
                    </div>
                }
            </div>
        );
    //Si tiene oferta pero ninguna tiene cantidad minima una entonces muestro el precio de oferta con la cantidad
    //y el precio comun en negrita con el fin de que el cliente vea que puede comprar a mejor precio
    return (
        <div className="text-end text-lg font-weight-bold tx-15">
            <div className="d-flex flex-column align-items-end">
                <span className="text-success text-end text-md font-weight-bold tx-12">
                    {`(x${articulo.articuloDescuentosAplicables[0].cantidadMinimaDescuento}) ${formatearPrecio({ valor: articulo.articuloDescuentosAplicables[0].precioUnitarioConDescuento })}`}
                </span>
                {/* Precio normal (para cantidad > 1) o precio con descuento (para cantidad = 1) */}
                <strong className={`text-end text-lg font-weight-bold tx-15 `}>
                    {formatearPrecio({ valor: articulo.articuloPrecio })}
                </strong>
            </div>
            {cantidadOfertas > 1 &&
                <div>
                    {/* Mostrar siempre el botón para múltiples ofertas */}
                    <BotonOfertasActivas
                        ofertas={articulo.articuloDescuentosAplicables}
                        precio={articulo.articuloPrecio}
                    />
                </div>
            }
        </div>
    );
};

PrecioProducto.propTypes = {
    articulo: PropTypes.any.isRequired
};

export default PrecioProducto;