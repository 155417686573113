import React, { useEffect, useMemo, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import APIFc from '../../../services/APIFc';
import { formatearFecha } from '../../../functions/Procedimientos';
import { useSelector } from 'react-redux';
import { decryptData } from '../../../functions/cryptoUtils';
import Descarga from '../../Descarga/Descarga';

const md5 = require('md5');

const Descargas = () => {
  const [descargas, setDescargas] = useState<any>();
  // const user = useSelector((store: any) => decryptData(store.authreducer.user));
      const encryptedUser = useSelector((store: any) => store.authreducer.user);
      const user = useMemo(() => decryptData(encryptedUser), [encryptedUser]);

  useEffect(() => {
    APIFc.get(`archivos/enableds`)
      .then(res => {
        setDescargas(res.data);
      })
      .catch(err => {
        console.error(err);
      });
  }, []);

  return (
    <div>
      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
          <span className="main-content-title mg-b-0 mg-b-lg-1">Descargas</span>
        </div>
      </div>

      <Row>
        <Col lg={12} xl={12}>
          <Card className="custom-card">
            <Card.Body className=" ht-100p">
              <div>
                <h6 className="card-title mb-1">
                  Ahora los reportes de información son generados automáticamente, la descarga puede demorar unos segundos en comenzar
                </h6>
              </div>
            </Card.Body>
          </Card>

          <Row>
            {descargas
              ? descargas.map((descarga: any, index: any) => (
                <Descarga
                  key={index}
                  archivoLink={descarga.archivosDescargaLink
                    .replace('CC', user.clienteCodigo)
                    .replace('HH', md5(`cV0puOlx${user.clienteCodigo}${new Date().getDate().toString().padStart(2, '0')}`))
                    .replace('FF', new Date().toISOString().slice(0, 10))}
                  archivoIcono={descarga.archivosDescargaIcono}
                  archivoDetalle={descarga.archivosDescargaDetalle}
                  archivoFecha={formatearFecha({ date: descarga.archivosDescargaFechaUltimaActualizacion })}
                  clienteCodigo={user.clienteCodigo}
                  mostrarBotonDescarga={false}
                  md5Hash={md5}
                />
              ))
              : (
                <div style={{ height: "250px", alignItems: "center", position: "relative" }}>
                  <span style={{ position: "absolute", bottom: "50%", left: "50%", whiteSpace: "nowrap" }}>No hay resultados</span>
                </div>
              )
            }
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Descargas;
