import axios from 'axios';
import { logOut } from '../redux/actions/auth';
import { declareAxiosError } from '../redux/actions/common';
import store from '../redux/store/store';
import { isUserLogued } from '../functions/Procedimientos';
import { decryptData } from '../functions/cryptoUtils';

var apiFc = axios.create();

// En caso de querer realizar una prueba y `https://backend.ferreteracentral.com.ar` no esté subido, se debe realizar la prueba en `http://192.168.88.47:4050`
// En esa situación comentar el primero y descomentar el segundo
apiFc.defaults.baseURL = `https://backend.ferreteracentral.com.ar`; // AWS back 
// apiFc.defaults.baseURL = `http://localhost:4050`; // Local
// apiFc.defaults.baseURL = `http://192.168.88.47:4050`; // Pruebas (back bruno)
apiFc.defaults.timeout = 1000000;

// Interceptor de solicitud para modificar la solicitud antes de enviarla
apiFc.interceptors.request.use(request => {
  // Si el usuario no está logueado, entonces no envío la cabecera con el Bearer token
  if (isUserLogued()) {
    request.headers['Authorization'] = 'Bearer ' + decryptData(store.getState().authreducer.token);
  }

  // Si es un POST, indico que en el contenido va un JSON, porque es el estándar que usamos
  if (request.method === "post") {
    request.headers['Content-Type'] = 'application/json';
  }

  // Función para generar una letra aleatoria entre 'a' y 'z'
  function getRandomLetter() {
    const letters = 'abcdefghijklmnopqrstuvwxyz';
    return letters.charAt(Math.floor(Math.random() * letters.length));
  }

  // Reemplazar el carácter backtick con una letra aleatoria
  if (request.url) {
    request.url = request.url.replace(/`/g, getRandomLetter());  // Reemplazar ` por una letra aleatoria
  }

  return request;
}, error => {
  // Manejo de errores antes de la solicitud si los hubiera
  return Promise.reject(error);
});

// Interceptor de respuesta para manejar la respuesta de la solicitud
apiFc.interceptors.response.use(response => {
  return response;
}, error => {
  // Si el error es de tipo 403, se hace logout y se muestra un mensaje de sesión expirada
  if (error.response.status === 403) {
    store.dispatch(declareAxiosError(false));
    store.dispatch(logOut("Sesión expirada. Por favor, ingrese nuevamente"));
  } else {
    // Si el error es otro, se muestra un error genérico
    store.dispatch(declareAxiosError(true));
    throw error;
  }
});

export default apiFc;
