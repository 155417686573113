import React, { useEffect, useRef, useState } from 'react';
import { Card, Col, InputGroup, Row } from 'react-bootstrap';
import { Doughnut } from "react-chartjs-2";
import Chart from 'chart.js/auto';
import apiFc from '../../../services/APIFc';
import ReactApexChart from 'react-apexcharts';
import { TablaDataBasica } from '../../common/TablaDataBasica';
import { ApexOptions } from 'apexcharts';
import { Snackbar, TextField } from '@mui/material';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { AlertDialogInterface } from '../../common/Interfaces';
import { isMobile } from 'react-device-detect';

registerLocale('es', es);

interface Movimiento {
    id: number;
    fechaCreacion: string;
    importe: number;
    ingresoEgreso: number;
    observacion: string;
    clienteCodigo: number;
    formaPago: {
        id: number;
        detalle: string;
    };
}

const Reporte = () => {
    const [incomeChart, setIncomeChart] = useState<any>(null);
    const [expenseChart, setExpenseChart] = useState<any>(null);
    const incomeChartRef = useRef<any>(null);
    const expenseChartRef = useRef<any>(null);
    const [data, setData] = useState<{ ingresosAgrupado: any[], egresosAgrupado: any[], movimientos: Movimiento[] } | null>(null);
    const customColors = ['#6d26be', '#ffbd5a', '#4ec2f0', '#1a9c86', '#f74f75'];
    const [incomeChartOptions, setIncomeChartOptions] = useState<any>({});
    const [reporteLista, setReporteLista] = useState([]);
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const obtenerFechasUnicas = () => {
        const fechasMovimientos = data?.movimientos.map(movimiento => new Date(movimiento.fechaCreacion).toLocaleDateString()) || [];
        const fechasUnicas: string[] = [];
        fechasMovimientos.forEach(fecha => {
            if (!fechasUnicas.includes(fecha)) {
                fechasUnicas.push(fecha);
            }
        });
        return fechasUnicas;
    };
    const [incomePerDay, setIncomePerDay] = useState<{ date: string, totalIncome: number }[]>([]);
    const [expensePerDay, setExpensePerDay] = useState<{ date: string, totalExpense: number }[]>([]);
    const [alertDialog, setAlertDialog] = useState<AlertDialogInterface>({
        id: "1",
        visible: false,
        severity: 'success', // severity="error" / "warning" / "info" / "success"
        timeOut: 2000, // severity="error" / "warning" / "info" / "success"
        cabecera: "Agregado!",
        texto: "el producto fue agregado.",
    });

    function Mensaje(props: any) {
        const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
            props,
            ref,
        ) {
            return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
        });

        const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
            if (reason === 'clickaway') {
                return;
            }
            setAlertDialog({ ...alertDialog, visible: false });
        };
        return (
            <Snackbar
                open={alertDialog.visible}
                autoHideDuration={alertDialog.timeOut}
                onClose={handleClose}
                //Aca decido si es mobile muestro arriba el cartelito, sino abajo
                anchorOrigin={(isMobile) ? { vertical: 'top', horizontal: 'center' } : { vertical: 'bottom', horizontal: 'right' }}>
                <Alert onClose={handleClose}
                    severity={(alertDialog.severity == "success") ? 'success' : 'warning'}
                    sx={{ width: '100%' }}>
                    <strong>{alertDialog.cabecera} </strong> {alertDialog.texto}
                </Alert>
            </Snackbar>
        )
    }

    const series = [
        {
            name: "Ingresos",
            data: incomePerDay.map(item => item.totalIncome),
        },
        {
            name: "Egresos",
            data: expensePerDay.map(item => item.totalExpense),
        },
    ]

    const primaryColor = getComputedStyle(document.documentElement).getPropertyValue("--primary-bg-color") || "#38cab3";

    const optiones: ApexOptions = {
        chart: {
            type: "bar",
            height: 280,
        },
        grid: {
            borderColor: "#f2f6f7",
            show: true,
        },
        colors: [primaryColor, "#e4e7ed"],
        plotOptions: {
            bar: {
                colors: {
                    ranges: [
                        {
                            from: -100,
                            to: -46,
                            color: "#ebeff5",
                        },
                        {
                            from: -45,
                            to: 0,
                            color: "#ebeff5",
                        },
                    ],
                },
                columnWidth: "40%",
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            show: true,
            width: 4,
            colors: ["transparent"],
        },
        legend: {
            show: true,
            position: "top",
        },
        xaxis: {
            categories: obtenerFechasUnicas(),
            axisBorder: {
                show: true,
                color: "rgba(119, 119, 142, 0.05)",
                offsetX: 0,
                offsetY: 0,
            },
            axisTicks: {
                show: true,
                borderType: "solid",
                color: "rgba(119, 119, 142, 0.05)",
                offsetX: 0,
                offsetY: 0,
            },
            labels: {
                rotate: -90,
            },
        },
        yaxis: {
            title: {
                text: "Monto",
                style: {
                    color: "#adb5be",
                    fontSize: "14px",
                    fontFamily: "poppins, sans-serif",
                    fontWeight: 600,
                    cssClass: "apexcharts-yaxis-label",
                },
            },
            labels: {
                formatter: function (y: any) {
                    return y.toFixed(0) + "";
                },
            },
        },
        fill: {
            opacity: 1
        },
    }


    const fetchData = async (startDate: Date, endDate: Date) => {
        try {
            if (startDate === null || endDate === null) {
                return;
            }
            
            const formattedStartDate = startDate.toISOString().split('T')[0];
            const formattedEndDate = endDate.toISOString().split('T')[0];
            const response = await apiFc.get(`web/caja/reporte?desde=${formattedStartDate}&hasta=${formattedEndDate}`);
            const data = response.data;
            setData(data);
            if (data && data.movimientos) {
                const groupedMovimientos = groupMovimientosByDay(data.movimientos);
                const incomePerDayData = calculateTotalIncome(groupedMovimientos);
                const expensePerDayData = calculateTotalExpense(groupedMovimientos);
                setIncomePerDay(incomePerDayData);
                setExpensePerDay(expensePerDayData);
            }
            const movimientosData = data?.movimientos || [];
            const formattedData = movimientosData.map((movimiento: any) => ({
                fechaCreacion: movimiento?.fechaCreacion,
                formaPago: movimiento?.formaPago?.detalle,
                ingresoEgreso: movimiento?.ingresoEgreso,
                importe: movimiento?.importe,
                observacion: movimiento?.observacion,
            }));
            setReporteLista(formattedData);
        } catch (error: any) {
            if (error.response && error.response.status === 409) {
                setAlertDialog({ ...alertDialog, visible: true, cabecera: "No puedes seleccionar una fecha mayor a 30 dias.", severity: "warning", texto: "" });
                const today = new Date();
                // const thirtyDaysAgo = new Date(today.getTime() + 1 * 24 * 60 * 60 * 1000);
                const oneDayAfterLastMonth = new Date(today);
                oneDayAfterLastMonth.setMonth(today.getMonth() - 1);
                oneDayAfterLastMonth.setDate(today.getDate() + 1);
                setStartDate(oneDayAfterLastMonth);
                setEndDate(today);
                return;
            }
        } 
    };

    const updateCharts = () => {
        if (data && data.ingresosAgrupado && data.egresosAgrupado) {
            // para ctualizar grafico de ingresos
            incomeChartRef.current.data.labels = data.ingresosAgrupado.map(item => item.formaPago.detalle);
            incomeChartRef.current.data.datasets[0].data = data.ingresosAgrupado.map(item => item.total);
            incomeChartRef.current.update();
            // para actualizar grafico de egresos
            expenseChartRef.current.data.labels = data.egresosAgrupado.map(item => item.formaPago.detalle);
            expenseChartRef.current.data.datasets[0].data = data.egresosAgrupado.map(item => item.total);
            expenseChartRef.current.update();
        }
    };

    useEffect(() => {
        updateCharts();
    }, [data]);


    useEffect(() => {
        const today = new Date();
        const oneDayAfterLastMonth = new Date(today);
        oneDayAfterLastMonth.setMonth(today.getMonth() - 1);
        oneDayAfterLastMonth.setDate(today.getDate() + 1);
        setStartDate(oneDayAfterLastMonth);
        setEndDate(today);
        fetchData(oneDayAfterLastMonth, today);
    }, []);

    const obtenerTextoIngresoEgreso = (ingresoEgreso: number) => {
        return ingresoEgreso === 1 ? 'Ingreso' : 'Egreso';
    };

    useEffect(() => {
        if (startDate !== null && endDate !== null) {
            fetchData(startDate, endDate);
        }
    }, [startDate, endDate]);

    // funcion para los movimientos del dia
    const groupMovimientosByDay = (movimientos: Movimiento[]) => {
        const groupedMovimientos: { [key: string]: Movimiento[] } = {};
        movimientos.forEach(movimiento => {
            const date = new Date(movimiento.fechaCreacion).toLocaleDateString();
            if (!groupedMovimientos[date]) {
                groupedMovimientos[date] = [];
            }
            groupedMovimientos[date].push(movimiento);
        });
        return groupedMovimientos;
    };

    // funcion para el total de ingresos POR DIA
    const calculateTotalIncome = (groupedMovimientos: { [key: string]: Movimiento[] }) => {
        const incomePerDayData: { date: string, totalIncome: number }[] = [];
        for (const date in groupedMovimientos) {
            const totalIncome = groupedMovimientos[date].reduce((total, movimiento) => {
                if (movimiento.ingresoEgreso === 1) {
                    return total + movimiento.importe;
                }
                return total;
            }, 0);
            incomePerDayData.push({ date, totalIncome });
        }
        return incomePerDayData;
    };

    // funcion para el total de egresos POR DIA
    const calculateTotalExpense = (groupedMovimientos: { [key: string]: Movimiento[] }) => {
        const expensePerDayData: { date: string, totalExpense: number }[] = [];
        for (const date in groupedMovimientos) {
            const totalExpense = groupedMovimientos[date].reduce((total, movimiento) => {
                if (movimiento.ingresoEgreso === 2) {
                    return total + movimiento.importe;
                }
                return total;
            }, 0);
            expensePerDayData.push({ date, totalExpense });
        }
        return expensePerDayData;
    };

    useEffect(() => {
        const ctxIncome = incomeChartRef.current?.getContext('2d');
        if (ctxIncome && !incomeChart && data && data.ingresosAgrupado) {
            setIncomeChart(new Chart(ctxIncome, {
                type: 'doughnut',
                data: {
                    labels: data.ingresosAgrupado.map((item: any) => item.formaPago.detalle),
                    datasets: [{
                        data: data.ingresosAgrupado.map((item: any) => item.total),
                        backgroundColor: customColors.slice(0, data.ingresosAgrupado.length),
                    }],
                },
                options: {
                    aspectRatio: 1,
                },
            }));
        }
    }, [data, incomeChart]);

    useEffect(() => {
        const ctxExpense = expenseChartRef.current?.getContext('2d');
        if (ctxExpense && !expenseChart && data && data.egresosAgrupado) {
            setExpenseChart(new Chart(ctxExpense, {
                type: 'doughnut',
                data: {
                    labels: data.egresosAgrupado.map((item: any) => item.formaPago.detalle),
                    datasets: [{
                        data: data.egresosAgrupado.map((item: any) => item.total),
                        backgroundColor: customColors.slice(0, data.egresosAgrupado.length),
                    }],
                },
                options: {
                    aspectRatio: 1,
                },
            }));
        }
    }, [data, expenseChart]);


    const reporteColumnas: any = [
        {
            Header: "Fecha Creación",
            accessor: (movimiento: any) => new Date(movimiento?.fechaCreacion).toLocaleDateString(),
            className: "wd-10p text-center borderrigth",
            rowClassName: 'text-center',
        },
        {
            Header: "Forma de pago",
            accessor: "formaPago",
            className: "wd-10p text-center borderrigth",
            rowClassName: 'text-center',
        },
        {
            Header: "Ingreso/Egreso",
            accessor: (movimiento: any) => obtenerTextoIngresoEgreso(movimiento?.ingresoEgreso),
            className: "wd-10p text-center borderrigth",
            rowClassName: 'text-center',
        },
        {
            Header: "Importe",
            accessor: "importe",
            className: "wd-10p text-center borderrigth",
            rowClassName: 'text-center',
        },
        {
            Header: "Observación",
            accessor: "observacion",
            className: "wd-10p text-center borderrigth",
            rowClassName: 'text-center',
        },
    ];

    const Datepicker = ({ selectedDate, onSelectDate }: { selectedDate: Date | null, onSelectDate: (date: Date | null) => void }) => {
        return (
            <ReactDatePicker
                className="form-control"
                selected={selectedDate}
                onChange={onSelectDate}
                locale="es"
                placeholderText=""
                dateFormat="dd/MM/yyyy"
            />
        );
    };

    return (
        <div>
            {/* <!-- breadcrumb --> */}
            <div className="breadcrumb-header justify-content-between">
                <div className="left-content wd-100p">
                    <span className="main-content-title mg-b-0 mg-b-lg-1">
                        Reporte
                    </span>
                    {alertDialog.visible && <Mensaje ></Mensaje>}
                    <Row>
                        <Col md={4}>
                            <Card>
                                <Card.Body>
                                    <div className="main-content-label mg-b-5">Fecha</div>
                                    <Row className="row-sm">
                                        <Col md={12}>
                                            <InputGroup className="input-group reactdate-pic mg-b-10">
                                                <InputGroup.Text className="input-group-text">
                                                    <i className="typcn typcn-calendar-outline tx-24 lh--9 op-6"></i>
                                                </InputGroup.Text>
                                                <Datepicker
                                                    selectedDate={startDate}
                                                    onSelectDate={(date) => setStartDate(date)}
                                                />
                                            </InputGroup>
                                        </Col>
                                        <Col md={12}>
                                            <InputGroup className="input-group reactdate-pic">
                                                <InputGroup.Text className="input-group-text">
                                                    <i className="typcn typcn-calendar-outline tx-24 lh--9 op-6"></i>
                                                </InputGroup.Text>
                                                <Datepicker
                                                    selectedDate={endDate}
                                                    onSelectDate={(date) => setEndDate(date)}
                                                />
                                            </InputGroup>
                                        </Col>
                                        {/* <!-- input-group --> */}
                                        {/* <!-- col-4 --> */}
                                    </Row>
                                </Card.Body>
                                <Col md={12}>
                                    <Card className="overflow-hidden">
                                        <Card.Body>
                                            <div className="main-content-label mg-b-5">Ingresos</div>
                                            {/* <p className="mg-b-20">Basic Charts Of Nowa template.</p> */}
                                            <div className="chartjs-wrapper-demo ">
                                                <Doughnut
                                                    data={{
                                                        labels: incomeChart?.data?.labels || [],
                                                        datasets: [{
                                                            data: incomeChart?.data?.datasets[0]?.data || [],
                                                            backgroundColor: incomeChart?.data?.datasets[0]?.backgroundColor || [],
                                                        }],
                                                    }}
                                                    ref={incomeChartRef}
                                                    id="chartDonutIngresos"
                                                    className="chartjs-render-monitor w-250 h-275"
                                                />
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={12}>
                                    <Card className=" overflow-hidden">
                                        <Card.Body>
                                            <div className="main-content-label mg-b-5">Egresos</div>
                                            <div className="chartjs-wrapper-demo ">
                                                <Doughnut
                                                    data={{
                                                        labels: expenseChart?.data?.labels,
                                                        datasets: [{
                                                            data: expenseChart?.data?.datasets[0].data,
                                                            backgroundColor: expenseChart?.data?.datasets[0]?.backgroundColor,
                                                        }],
                                                    }}
                                                    ref={expenseChartRef}
                                                    id="chartDonutEgresos"
                                                    className="chartjs-render-monitor w-250 h-275"
                                                />
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Card>
                        </Col>
                        <Col md={8}>
                            <Row>
                                <Col md={12} >
                                    <Card className="custom-card overflow-hidden ">
                                        <Card.Header className="border-bottom-0">
                                            <div>
                                                <h3 className="card-title mb-2">Ingresos por día</h3>
                                            </div>
                                        </Card.Header>
                                        <Card.Body>
                                            <div id="chart">
                                                <ReactApexChart
                                                    options={optiones}
                                                    series={series}
                                                    type="bar"
                                                    width="100%"
                                                    height={300}
                                                />
                                            </div>
                                        </Card.Body>
                                    </Card>
                                    <Card>
                                        <Card.Body>
                                            <div className="table-responsive-container">
                                                <TablaDataBasica
                                                    columns={reporteColumnas}
                                                    data={reporteLista}
                                                    mostrarCantidadRgistros={true}
                                                    mostrarFiltro={true}
                                                />
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
}

export default Reporte
